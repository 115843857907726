import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const TransactionTypesDropdown = ({value, onChange}) => {
	return (
		<TextField
			select
			label="Type"
			fullWidth
			defaultValue=""
			onChange={onChange}
			value={value}
		>
			<MenuItem value="">All</MenuItem>
			<MenuItem value="fnol">FNOL</MenuItem>
			<MenuItem value="invoice">Invoice</MenuItem>
			<MenuItem value="invoice status update">Invoice Status Update</MenuItem>
			<MenuItem value="payment remittance">Payment Remittance</MenuItem>
			<MenuItem value="retailer availability">Retailer Availability</MenuItem>
			<MenuItem value="work assignment">Work Assignment</MenuItem>
		</TextField>
	)
}
export default TransactionTypesDropdown