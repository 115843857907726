export function canResend(type) {
	const resendables = ['FNOL', 'invoice', 'work assignment']
	return resendables.includes(type)
}

async function fetchData(id_jwt, query, endpoint) {
	const args = {
		method: 'GET',
		credentials: "include",
		headers: {
			Accept: 'application/json',
			'Authorization': `Bearer ${id_jwt}`
		},
	}
	const port = window.location.port ? `:${window.location.port}` : ''
	const currentHost = `${window.location.protocol}//${window.location.hostname}${port}`
	let host = process.env.NODE_ENV === 'production' ? currentHost : 'http://localhost:8001'

	let result = await fetch( `${host}/api/${endpoint}?${query}` , args)
		.then(response => {
			if(response.status === 204) {
				return ({response})
			} else {
				return response.json().then(json => ({json, response}))
			}
		}).then(({json, response}) => {
			if(!response.ok) {
				return Promise.reject(json)
			}
			return json || true
		})
		.then(
			response => ({response}),
			error => ({error: error || {error_code: null, message: 'Something bad happened'}})
		)

	if(result.error && result.error.message === "Authorization token is expired") {
		alert("Authorization token is expired. Please refresh the page")
	}
	return result;
}

export async function fetchRepositoryData(id_jwt, props) {
	const {GUID='', type='', origin='', destination='', status='', date1, date2, filter=''} = props
	const formattedDate1 = date1? date1.format('YYYY-MM-DD') : ''
	const formattedDate2 = date2.format('YYYY-MM-DD')

	let query = `guid=${GUID}&type=${type}&origin=${origin}&destination=${destination}&status=${status}&date1=${formattedDate1}&date2=${formattedDate2}&filter=${filter}`

	let result = await fetchData(id_jwt, query, 'Repository');
	return result.response
}

export async function fetchInvoiceValidationRules(id_jwt, props) {
	let query = ``

	return await fetchData(id_jwt, query, 'Invoice/ValidationRules')
}

export async function postToBackend(id_jwt, props) {
	const {body, path} = props
	const args = {
		method: 'POST',
		credentials: "include",
		headers: {
			Accept: 'application/json',
			'Authorization': `Bearer ${id_jwt}`,
			'Content-Type': 'application/json'
		},
		body: body
	}
	const port = window.location.port ? `:${window.location.port}` : ''
	const currentHost = `${window.location.protocol}//${window.location.hostname}${port}`
	let host = process.env.NODE_ENV === 'production' ? currentHost : 'http://localhost:8001'


	return await fetch(host+'/api'+path, args)
		.then(response => {
			if(response.status === 204) {
				return ({response})
			} else {
				return response.json().then(json => ({ json, response }))
			}
		}).then(({ json, response }) => {
			if (!response.ok) {
				return Promise.reject(json)
			}
			return json || true
		})
		.then(
			response => ({response}),
			error => ({error: error || {error_code: null, message: 'Something bad happened'} } )
		)
}
