import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LeftNav from "./LeftNav";

export default class MainAppBar extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
			leftNavOpen: false
		}
	}
	render() {
		return (
			<Box sx={{flexGrow: 1}}>
				<AppBar position="static">
					<Toolbar>
						{/*<IconButton*/}
						{/*	size="large"*/}
						{/*	edge="start"*/}
						{/*	color="inherit"*/}
						{/*	aria-label="menu"*/}
						{/*	sx={{mr: 2}}*/}
						{/*	onClick={() => this.setState({leftNavOpen: !this.state.leftNavOpen})}*/}
						{/*>*/}
						{/*	<MenuIcon/>*/}
						{/*</IconButton>*/}
						<LeftNav/>
						<Typography variant="h6" component="div" sx={{flexGrow: 1}}>
							{this.props.title}
						</Typography>
						<Button color="inherit" onClick={this.props.signOut}>Log Out</Button>
					</Toolbar>
				</AppBar>
			</Box>
		)
	}
}