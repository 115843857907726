import React from 'react'
import Button from '@mui/material/Button';
import {Box, CircularProgress, Grid, Paper, TextField} from "@mui/material";
import RepositoryTable from "./RepositoryTable";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import MainAppBar from "./MainAppBar";
import TransactionTypesDropdown from './TransactionTypesDropdown'
import {fetchRepositoryData} from "./Utils";

class Repository extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			GUID: '',
			type: '',
			origin: '',
			data: null,
			date1: moment(),
			date2: moment(),
			destination: '',
			filter: '',
			status: ''
		}
	}

	async GetData(id_jwt) {
		this.setState({data: 'loading'})
		const response = await fetchRepositoryData(id_jwt,  this.state)

		if(response) {
			this.setState({data: response})
		}
	}

	render() {
		return (
			<Box>
				<MainAppBar title="Repository" signOut={this.props.signOut}/>
				<Paper elevation={2} square={false}
					sx={{
						'& .MuiTextField-root': { m: 1},
					}}
				>
					<Paper elevation={2} square={false} style={{maxWidth: 1280, margin: 'auto'}}>
					<Grid 
						container 
						spacing={1}
						style={{maxWidth: 1280, margin: 'auto'}}
					>
						<Grid item xs={6} md={2}>
							<TextField
								label="Search"
								type="search"
								onChange={(e) => this.setState({filter: e.target.value})}
								value={this.state.filter}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} md={2}>
							<TransactionTypesDropdown
								onChange={(e) => this.setState({type: e.target.value})}
								value={this.state.type}
							/>
						</Grid>
						{false && <Grid item xs={6} md={2}>
							<TextField
								label="Origin"
								type="search"
								onChange={(e) => this.setState({origin: e.target.value})}
								value={this.state.origin}
								fullWidth
							/>
						</Grid>}
						{false && <Grid item xs={6} md={2}>
							<TextField
								label="Destination"
								type="search"
								onChange={(e) => this.setState({destination: e.target.value})}
								value={this.state.destination}
								fullWidth
							/>
						</Grid>}
						<Grid item xs={6} md={2}>
							<DatePicker
								label="From"
								value={this.state.date1}
								onChange={(newValue) => this.setState({date1: newValue})}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} md={2}>
							<DatePicker
								label="To"
								value={this.state.date2}
								onChange={(newValue) => this.setState({date2: newValue})}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} md={4} style={{paddingTop: 24}}>
							<Button
								onClick={() => {this.GetData(this.props.id_jwt)}}
								primary
								raised
								disabled={this.state.data === 'loading'}
							>
								Search
							</Button>
						</Grid>
					</Grid>
					</Paper>
					{this.state.data === 'loading' && (
						<CircularProgress size={"6rem"} style={{padding: '16px'}}/>
					)}
					{this.state.data !== 'loading' && (
						<RepositoryTable
							Rows={this.state.data}
							id_jwt={this.props.id_jwt}
							onGetData={() => this.GetData(this.props.id_jwt)}
						/>
					)}
				</Paper>
			</Box>
		)
	}
}

export default (Repository)