import React from "react";
import {DataGrid} from '@mui/x-data-grid';
import {Container} from "@mui/material";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {canResend, postToBackend} from "./Utils";


const renderActions = (state, props) => {
	const resending = state.resending === props.id
	return (
		<div>
			<Button onClick={() => props.row.onOpenDetails()}>
				Details
			</Button>
			|
			<Button color="secondary" onClick={() => props.row.onResend()} disabled={resending || !canResend(props.row.type)}>
				Resend
			</Button>

		</div>
	)
}

class RepositoryTable extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			dialogOpen: false,
			dialogRow: null,
			resending: ''
		}
	}

	async handleResend(transaction, id_jwt) {
		// console.log("Resending")
		// console.log(transaction)
		this.setState({resending: transaction.id})

		let body = JSON.parse(transaction.content)
		body.id = transaction.id
		body.guid = transaction.guid
		body.Created = transaction.created
		body.RetryCount = -1
		body = JSON.stringify(body)

		let path

		switch(transaction.type) {
			case 'invoice':
				path = "/Invoice"
				break;
			case 'work assignment':
				path = "/WorkAssignment"
				break;
			case 'FNOL':
				path = "/FNOL"
				break;
			default:
				return
		}

		await postToBackend(id_jwt,{path, body})

		await this.props.onGetData()
		this.setState({resending: ''})
	}

	handleOpenDialog(row) {
		this.setState({
			dialogOpen: true,
			dialogRow: row 
		})
	}

	render() {
		const columns = [
			{field: 'guid', headerName: 'GUID', minWidth: 160, flex: 1.5},
			{field: 'type', headerName: 'Type', minWidth: 160, flex: 1.5},
			{field: 'origin', headerName: 'Origin', minWidth: 100, flex: 1.5},
			{field: 'destination', headerName: 'Destination', minWidth: 100, flex: 1.5},
			{field: 'status', headerName: 'Status', minWidth: 100, flex: 1, renderCell: (params) => (
					<span style={{color: params.value === 'failed' || params.value === 'REJECTED' ? 'red' : 'inherit'}}>{params.value}</span>
				)},
			{field: 'created', headerName: 'Date Created', minWidth: 160, flex: 1.5},
			{field: 'actions', headerName: 'Actions', minWidth: 160, flex: 1.5, disableClickEventBubbling: true, renderCell: (props) => renderActions(this.state, props)}
		]

		return (
			<Container
				sx={{
					height: 'auto',
					padding: 1
				}}
			>
				{this.props.Rows && this.props.Rows.length > 0 &&
					<DataGrid
						columns={columns}
						rows={this.props.Rows.map(row => {
							return {
								...row,
								onOpenDetails: () => {
									this.handleOpenDialog(row)
								},
								onResend: () => {
									this.handleResend(row, this.props.id_jwt)
								}
							}
						})}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 50 },
							},
							sorting: {
								sortModel: [{ field: 'created', sort: 'desc' }],
							}
						}}
						pageSizeOptions={[10, 50, 100]}
						disableRowSelectionOnClick
					/>
				}
				{this.props.Rows && this.props.Rows.length === 0 &&
					<b>No Matches</b>
				}


				{this.state.dialogRow && <Dialog
					open={this.state.dialogOpen}
					onClose={() => this.setState({dialogOpen: false})}
					maxWidth="lg"
				>
					<DialogTitle>
						Transaction Details - {this.state.dialogRow.guid}<br/>
						<span style={{fontSize: 12}}>
							{this.state.dialogRow.type}<br/>
							{this.state.dialogRow.created}
						</span>
					</DialogTitle>
					<DialogContent>
						<SyntaxHighlighter
							language="json"
						>
							{
								JSON.stringify(JSON.parse(this.state.dialogRow.content),null,2)
							}
						</SyntaxHighlighter>
					</DialogContent>
				</Dialog>}
			</Container>
		)
	}
}

export default (RepositoryTable)