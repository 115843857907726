import * as React from "react";
import Box from "@mui/material/Box";
import {Drawer, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";

export default function LeftNav() {
	const [open, setOpen] = React.useState(false);

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};
	const navigate = useNavigate();

	const DrawerList = (
		<Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
			<List>
				<ListItem key="Repository" disablePadding>
					<ListItemButton onClick={() => navigate("/Repository")}>
						<ListItemText>Repository</ListItemText>
					</ListItemButton>
				</ListItem>
				<ListItem key="ReviewPending" disablePadding>
					<ListItemButton onClick={() => navigate("/ReviewPending")}>
						<ListItemText>Review Pending</ListItemText>
					</ListItemButton>
				</ListItem>
				<ListItem key="InvoiceValidationRules" disablePadding>
					<ListItemButton onClick={() => navigate("/InvoiceValidationRules")}>
						<ListItemText>Invoice Validation Rules</ListItemText>
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	return (
		<div>
			<IconButton
				size="large"
				edge="start"
				color="inherit"
				aria-label="menu"
				sx={{mr: 2}}
				onClick={toggleDrawer(true)}
			>
				<MenuIcon/>
			</IconButton>
			<Drawer open={open} onClose={toggleDrawer(false)}>
				{DrawerList}
			</Drawer>
		</div>
	);
}