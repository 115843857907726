import React from 'react'

import {Grid, Paper} from "@mui/material";


export default class InvoicePreview extends React.Component {

	render() {
		const {
			// SourceReference,
			AppointmentDate,
			AppointmentCompletionDate,
			InvoiceDate,
			// DateOfLoss,
			// LossReason,
			// RetailerInvoiceNumber,
			Retailer,
			// RetailerID,
			RetailerAddress,
			// Destination,
			// DestinationID,
			// Notes,

			CustomerFirstName,
			CustomerSurname,
			CustomerPrimaryPhone,
			CustomerAddress,
			// CustomerEmail,
			// PolicyNumber,
			Items,
			// InvoiceAmount,
			// Deductible,
			// TaxTotal,
			// TaxExempt,
			// TaxDetail,
			// Attachments,
			Vehicle,
			// Validations

		} = this.props.Invoice

		return (
			<Grid container spacing={2} style={{padding: 24, backgroundColor: '#f6f6f6'}}>
				<Grid item xs={6}>
					<b>Invoice Date:</b> {InvoiceDate.substr(0, 10)}<br/><br/>
					{Retailer}<br/>
					{RetailerAddress.Address}<br/>
					{RetailerAddress.Address2 ? [RetailerAddress.Address2, <br/>] : null}
					{`${RetailerAddress.City}, ${RetailerAddress.StateOrProvince} ${RetailerAddress.PostalCode}`}<br/>
					
				</Grid>
				<Grid item sx={6}>
					Scheduled: {AppointmentDate}<br/>
					Completed: {AppointmentCompletionDate}
				</Grid>
				<Grid item xs={6}>
					<h3>Vehicle Owner</h3>
					<Paper style={{padding: 24}}>
						{CustomerFirstName} {CustomerSurname}<br/>
						{CustomerAddress.Address}<br/>
						{CustomerAddress.Address2 ? [CustomerAddress.Address2, <br/>] : null}
						{`${CustomerAddress.City}, ${CustomerAddress.StateOrProvince} ${CustomerAddress.PostalCode}`}<br/>
						{CustomerPrimaryPhone}
					</Paper>
				</Grid>
				<Grid item xs={6}>
					<h3>Vehicle</h3>
					<Paper style={{padding: 24}}>
						{Vehicle.Year}<br/>
						{Vehicle.Make}<br/>
						{Vehicle.Model} {Vehicle.Descriptor}<br/>
						{Vehicle.VIN}
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<h3>Items</h3>
					<Paper style={{padding: 24}}>
						<table>
							<tbody>
								<tr>
									<th>Qty</th><th>UOM</th><th>Item</th><th>Description</th><th>List</th><th>Extended</th>
								</tr>
								{Items.map(item => {
									return (
										<tr>
											<td>{item.quantity}</td>
											<td>{item.unitOfMeasure}</td>
											<td>{item.sku}</td>
											<td>{item.listPrice}</td>
											<td>{item.description}</td>
											<td>{item.price}</td>
											<td>{(item.price * item.quantity).toFixed(2)}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid item xs={6}>

				</Grid>
			</Grid>
		)
	}
}