import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'isomorphic-fetch'
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Repository from './Repository'
import ReviewPending from "./ReviewPending";

import {Amplify} from 'aws-amplify';
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import InvoiceValidationRules from "./InvoiceValidationRules";


Amplify.configure({
   Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_7KhB8f3NU',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '4t23phtdno557bvsqbmh5sddr7',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //    domain: '.yourdomain.com',
      //    // OPTIONAL - Cookie path
      //    path: '/',
      //    // OPTIONAL - Cookie expiration in days
      //    expires: 365,
      //    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //    sameSite: 'strict' | 'lax',
      //    // OPTIONAL - Cookie secure flag
      //    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //    secure: true,
      // },

      // OPTIONAL - customized storage object
      // storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'


      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: {myCustomKey: 'myCustomValue'},

      // OPTIONAL - Hosted UI configuration
      // oauth: {
      //    domain: 'https://highbeam-dev.auth.us-east-1.amazoncognito.com',
      //    scope: [
      //       'phone',
      //       'email',
      //       'name',
      //       'profile',
      //       'openid',
      //       'aws.cognito.signin.user.admin',
      //    ],
      //    redirectSignIn: 'http://localhost:3000/',
      //    redirectSignOut: 'http://localhost:3000/',
      //    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      // },
   },
});

export default function App() {
   const formFields = {
      signUp: {
         'custom:pos': {label: 'Point of Sale'},
         'custom:pos_account_id': {label: 'Point of Sale Account ID'},
         'custom:settings_access': {label: 'What settings can be accessed'}
      }
   }

   return (
      <Authenticator
         formFields={formFields}
         signUpAttributes={['email','name']}
      >
         {({signOut, user}) => (
            <div className="App">
               <LocalizationProvider dateAdapter={AdapterMoment}>
                  <ErrorBoundary>
                  <BrowserRouter>
                     <Routes>
                        <Route path="/"
                               element={<Repository
                                  id_jwt={user.getSignInUserSession().getIdToken().getJwtToken()}
                                  signOut={signOut}
                               />}
                        />
                        <Route path="/Repository"
                               element={<Repository
                                  id_jwt={user.getSignInUserSession().getIdToken().getJwtToken()}
                                  signOut={signOut}/>}
                        />
                        <Route path="/ReviewPending"
                               element={<ReviewPending
                                  id_jwt={user.getSignInUserSession().getIdToken().getJwtToken()}
                                  signOut={signOut}/>}
                        />
                        <Route path="/InvoiceValidationRules"
                               element={<InvoiceValidationRules
                                  id_jwt={user.getSignInUserSession().getIdToken().getJwtToken()}
                                  signOut={signOut}/>}
                        />
                        <Route path="*" element={<div>Not Found</div>} status={404}/>
                     </Routes>
                  </BrowserRouter>
                  </ErrorBoundary>
                  {/*{console.log(user)}*/}
               </LocalizationProvider>
            </div>
         )}
      </Authenticator>
   );
}

