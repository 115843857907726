import React from 'react'
import {Alert, Box, Card, CardActions, Grid, LinearProgress, Paper, Select, TextField} from "@mui/material";
import MainAppBar from "./MainAppBar";
import {fetchInvoiceValidationRules, postToBackend} from "./Utils";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

class InvoiceValidationRules extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			alert: null
		}
	}

	componentDidMount() {
		if(!this.state.data){
			this.GetData(this.props.id_jwt)
		}
	}

	async GetData(id_jwt) {
		const response = await fetchInvoiceValidationRules(id_jwt,  this.state)

		if(response.response) {
			this.setState({data: response.response})
		}
		if(response.error) {
			this.setState({data: 'error'})
			this.setState({alert: <div style={{marginTop: '12px'}}><Alert severity={'error'}>{response.error.message}</Alert></div>})
		}
	}

	changeField(rule, field, value) {
		let data = this.state.data;
		data[rule][field] = value
		this.setState({data: data})
	}

	handleReset() {
		this.setState({alert: null})
		this.GetData(this.props.id_jwt)
	}

	async handleSave() {
		this.setState({alert: null})
		let body = JSON.stringify(this.state.data)

		const path = "/Invoice/ValidationRules"

		const {error} = await postToBackend(this.props.id_jwt, {path, body})
		if(error && error.message) {
			this.setState({alert: <Alert severity={'error'}>Settings failed to save</Alert>})
		} else {
			this.setState({alert: <Alert severity={'success'}>Settings saved successfully</Alert>})
				}
					await this.GetData(this.props.id_jwt)
	}

	render() {
		let data = this.state.data;
		if(data && data !== 'error') {
			ruleNames.forEach((ruleName) => {
				if(!data.hasOwnProperty(ruleName)) {
					data[ruleName] = {"RuleType": "exact", "RuleAction": "reject", "RejectMessage": ""}
				}
			})
			data = Object.entries(this.state.data);
			data.sort()
		}
		const alert = this.state.alert;

		return (
			<Box>
				<MainAppBar title="Invoice Validation Rules" signOut={this.props.signOut}/>
				<Paper elevation={2} square={false}
					sx={{
						'& .MuiTextField-root': { m: 1},
					}}
				>
					{!data && <LinearProgress />}
					<Card sx={{ maxWidth: 1280, margin: '1em auto 1em auto', padding: 2}}>
					<Grid
						container
						direction="column"
						spacing={1}
						style={{maxWidth: 1280, margin: 'auto'}}
					>
						<Grid container item columnSpacing={2} spacing={1} align='left' >
							<Grid item xs={2}>
								<Typography variant="h6">Rule Name</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">Rule Type</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">Rule Value</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">Rule Action</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="h6">Reject Message</Typography>
							</Grid>
						</Grid>

						{data !== null && data !== 'error' && data
							.map((Rule, index) => {
								return (
									<InvoiceValidationRule Rule={Rule} onChange={(rule, field, value) => this.changeField(rule, field, value)} key={index}/>
								)
							})
						}
					</Grid>
						<div style={{marginTop: '12px'}}>{alert}</div>
							<CardActions sx={{float: 'right', paddingTop: 2}}>
							<Button size="small" color="success" variant="outlined" onClick={() => this.handleSave()}>Save</Button>
							<Button
								size="small"
								color="error"
								variant="outlined"
								onClick={() => this.handleReset()}
							>
								Reset
							</Button>
						</CardActions>
					</Card>
				</Paper>
			</Box>
		)
	}
}

class InvoiceValidationRule extends React.Component {

	render() {
		const name = this.props.Rule[0]
		const rule = this.props.Rule[1]

		return (
			<Grid container item align='left' alignItems='center' columnSpacing={2} sx={{marginTop: 1}}>
				<Grid item xs={2} >
					<Typography fontWeight='bold'>{name}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Select
						value={rule.RuleType}
						onChange={(e) => this.props.onChange(name, 'RuleType', e.target.value)}
						fullWidth={true}
					>
						<MenuItem value={"exists"}>Exists</MenuItem>
						<MenuItem value={"exact"}>Exact</MenuItem>
						<MenuItem value={"window_plus_minus"}>Window (+ or -)</MenuItem>
						<MenuItem value={"window_percentage"}>Window (%)</MenuItem>
						<MenuItem value={"maximum"}>Maximum</MenuItem>
						<MenuItem value={"minimum"}>Minimum</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={2}>
					<TextField
						value={rule.RuleValue}
						onChange={(e) => this.props.onChange(name, 'RuleValue', e.target.value)}
						fullWidth={true}
						style={{margin: 'auto'}}
						disabled={rule.RuleType === "exact" || rule.RuleType === "exists"}
						variant={rule.RuleType === "exact" || rule.RuleType === "exists" ? "filled" : "outlined"}
					/>
				</Grid>
				<Grid item xs={2}>
					<Select
						value={rule.RuleAction}
						onChange={(e) => this.props.onChange(name, 'RuleAction', e.target.value)}
						fullWidth={true}
					>
						<MenuItem value={"reject"}>Reject</MenuItem>
						<MenuItem value={"review"}>Review Manually</MenuItem>
						<MenuItem value={"ignore"}>Ignore</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={4}>
					<TextField
						value={rule.RejectMessage}
						onChange={(e) => this.props.onChange(name, 'RejectMessage', e.target.value)}
						fullWidth={true}
						style={{margin: 'auto'}}
					/>
				</Grid>
			</Grid>
		)
	}
}

const ruleNames = [
	"deductible",
	"glass_position",
	"item_list_price",
	"item_nags_fitment",
	"item_pricing",
	"item_sku",
	"retailer_id",
	"vehicle_year",
	"vehicle_make",
	"vehicle_model",
	"vehicle_nags_id",
	"vin"]

export default (InvoiceValidationRules)