import React, {useEffect, useState} from 'react'
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogTitle,
	Grid,
	LinearProgress,
	Paper,
	TextField,
	Typography
} from "@mui/material";

import {red} from '@mui/material/colors';

import MainAppBar from "./MainAppBar";
import {fetchRepositoryData, postToBackend} from "./Utils";
import moment from "moment";
import Button from "@mui/material/Button";

import InvoicePreview from './InvoicePreview'

const ReviewPending = (props) => {
	const [data, setData] = useState()

	useEffect(() => {
		GetData(props.id_jwt)
	}, [props.id_jwt])

	const GetData = async (id_jwt) => {
		let params = {"type": "invoice", "status" : "PENDING", "date1" : moment("2000-01-01"), "date2" : moment()}
		const response = await fetchRepositoryData(id_jwt,  params)
		setData(response)
	}

	const onAccept = async (guid) => {
		console.log("Accepting")

		await resolvePendingTransaction(guid, "ACCEPTED", "Manually accepted")
	}

	const onReject = async (guid, reason) => {
		console.log("Rejecting")
		console.log("Reason: "+reason)

		await resolvePendingTransaction(guid, "REJECTED", reason)
	}

	const resolvePendingTransaction = async (guid, status, returnText) => {
		let body = {}
		body.InvoiceGUID = guid
		body.InvoiceStatus = status
		body.InvoiceReturnText = returnText
		body = JSON.stringify(body)

		const path = "/Invoice/StatusUpdate"

		await postToBackend(props.id_jwt,{path, body})
		await GetData(props.id_jwt)
	}

	return (
		<Box>
			<MainAppBar title="Review Pending" signOut={props.signOut}/>
			<Paper 
				elevation={2} 
				square={false}
				sx={{
					'& .MuiTextField-root': { m: 1},
					padding: 1,
					backgroundColor: '#f3f6f999'
				}}
			>
				{!data && <LinearProgress />}
				{data && data
				.filter(val => !val.approved && !val.rejected)
				.map(Transaction => {
					return (
						<ReviewPendingCard
							Transaction={Transaction}
							onAccept={onAccept}
							onReject={onReject}
						/>
					)
				})}

			</Paper>
		</Box>
	)
}

class ReviewPendingCard extends React.Component {

	constructor(props) {

		super(props)

		this.state = {
			rejected_reason: null,
			dialogOpen: false
		}
	}
	
	handleOpenInvoiceDialog() {
		this.setState({
			dialogOpen: true
		})
	}

	render() {
		const {
			content,
			created,
			origin
			} = this.props.Transaction

		const Content = JSON.parse(content)
		const {SourceReference, ReturnText, RetailerInvoiceNumber} = Content

		const {rejected_reason} = this.state

		// const Errors = []

		// let ErrorsArray = []

		// for (const key in Errors) {
		// 	ErrorsArray.push(Errors[key])
		// }

		return (
			<Card
				sx={{ maxWidth: 900, margin: '1em auto 1em auto', padding: 2}}
			>
				<CardHeader
					title={origin}
					subheader={created.substr(0, 16)}
					avatar={
			          <Avatar sx={{ bgcolor: red[500] }} aria-label="Origin">
			          		{origin.substr(0, 1)}
			          </Avatar>
			        }
				/>
				<CardContent>
					<Grid item>
						<Typography variant="subtitle2" gutterbottom={true} sx={{color: 'text.secondary', marginTop: '-5px'}}>
							{}
						</Typography>
					</Grid>
				</CardContent>
				<Grid container align='left' sx={{marginTop: 1}}>
					<Grid
						container
						direction='column'
						xs={2}
						sx={{fontWeight: 'bold'}}
					>
						<Grid item>
							Invoice #
						</Grid>
						<Grid item>
							Claim
						</Grid>
						<Grid item>
							Return Text
						</Grid>
					</Grid>
					<Grid
						container
						direction='column'
						xs={6}
					>
						<Grid item xs={2}>
							{/*eslint-disable-next-line*/}
							<a onClick={() => this.handleOpenInvoiceDialog()} style={{color: 'blue', textDecoration: 'underline'}}>{RetailerInvoiceNumber}</a>
						</Grid>
						<Grid item xs={2}>
							{SourceReference}
						</Grid>
						<Grid item xs={2} sm={4}>
							{ReturnText}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					direction='column'
					alignItems='flex-end'
					sx={{float: 'right'}}
					xs={3}
				>
					<Grid item >
						<TextField
							label='Rejection Reason'
							size='small'
							onChange={(e) => this.setState({rejected_reason: e.target.value})}
						/>
					</Grid>
					<CardActions sx={{float: 'right'}}>
						<Button size="small" color="success" variant="outlined" onClick={() => this.props.onAccept(this.props.Transaction.guid)}>Accept</Button>
						<Button
							size="small"
							color="error"
							variant="outlined"
							onClick={() => this.props.onReject(this.props.Transaction.guid, rejected_reason)}
							disabled={!rejected_reason}
						>
							Reject
						</Button>
					</CardActions>
				</Grid>

				<Dialog 
					open={this.state.dialogOpen} 
					onClose={() => this.setState({dialogOpen: false})}
					maxWidth="xl"
					fullWidth={true}
				>
					<DialogTitle>Invoice #{Content.RetailerInvoiceNumber}</DialogTitle>
					<InvoicePreview 
						Invoice={Content}
					/>
				</Dialog>
			</Card>
		)
	}
}

export default (ReviewPending)